window.Client = {
	init: function() {
		// Do something on initialization
	},

	onRender: function(screenId) {
		// Do something when the flow renders
		document.querySelector('[data-variable="variant"]').value = windowConstants.VARIANT_ID;
	}
}




// IP Address Capture
const fetchIpAddress = async () => {
    // Get IP Address from an external link to fetch the global IP
    const getIPAddress = async () => {
      try {
        const response = await fetch(
          "https://europe-north1-heyflow-custom-solutions.cloudfunctions.net/fetchIp"
        );
        const data = await response.json();
        if (data.ip) {
          return data.ip;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    try {
      const ipInputField = document.querySelector(
        `[data-variable="ipAddress"]`
      );

      if (ipInputField != null) {
        const ipAddress = await getIPAddress();
        ipInputField.value = ipAddress;
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  
  window.addEventListener("heyflow-init", event => {
      fetchIpAddress();
  });
  
  // session length monitoring
  (function() {
  // Define start time
  var startTime = Date.now();

  // Function to get session length in seconds
  function getSessionLength() {
    return Math.floor((Date.now() - startTime) / 1000);
  }

  // Find the existing hidden input element
  var sessionLengthInput = document.querySelector('input[data-label="sessionLength"]');

  // Check if the element exists
  if (sessionLengthInput) {
    // Function to update the hidden input value
    function updateSessionLength() {
      sessionLengthInput.value = getSessionLength();
    }

    // Call updateSessionLength immediately
    updateSessionLength();

    // Set up an interval to update every 100 milliseconds
    setInterval(updateSessionLength, 100);
  } else {
    console.error("Element with ID 'sessionLength' not found.");
  }

  // Add submit event listener to the form
  document.addEventListener('submit', function(event) {
    var form = event.target;
  });
})();


// capture user agent

function getUserAgent() {
  return navigator.userAgent;
}

// Find the existing input element with data-label="sessionLength"
var userAgentInput = document.querySelector('input[data-label="userAgent"]');

// Check if the element exists
if (userAgentInput) {
  // Get the user agent
  var userAgent = getUserAgent();

  // Set the value of the hidden input field
  userAgentInput.value = userAgent;
} else {
  console.error("Element with data-label 'userAgent' not found.");
}

// get the url of the current page and store it

(function() {
  // Get the base URL (protocol, host, and port)
  var baseUrl = window.location.origin;

  // Get the path without parameters or fragments
  var pathname = window.location.pathname.split('?')[0];

  // Combine base URL and path
  var url = baseUrl + pathname;

  // Find the existing input element with data-label="originalUrl"
  var originalUrlInput = document.querySelector('input[data-label="originalUrl"]');

  // Check if the element exists
  if (originalUrlInput) {
    // Set the value of the hidden input field
    originalUrlInput.value = url;
  } else {
    console.error("Element with data-label 'originalUrl' not found.");
  }
})();

